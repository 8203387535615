module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-a4b900b813/0/cache/gatsby-plugin-styled-components-npm-5.24.0-de55e1696b-0e92f5f443.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-a3a7f97e1d/0/cache/gatsby-remark-autolink-headers-npm-5.24.0-b32f49afe6-3d2c4a745c.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-1886f3a9fe/0/cache/gatsby-plugin-google-analytics-npm-4.24.0-7f75d7608c-390fe9d54a.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-102906433-1","respectDNT":true,"exclude":["/public/**","/admin/**"],"head":false,"anonymize":false,"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-76c18194a3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
